import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
// import Layout from '../components/Layout'
import Content, { HTMLContent } from "../components/Content";

import Layout from "../components/Layout";
// import SEO from "../components/seo"
import styled, { css } from "styled-components";
import backroundPath from "../img/logo.svg";
import backroundPathYellow from "../img/logo-yellow.svg";
import SvgBackground from "../components/SvgBackground";

export const HomeContent = styled.div`
  /* position: absolute; */
  text-align: center;
  width: 100%;
  /* height: 100vh; */
  position: relative;
  height: 100%;
  min-height: 100vh;
`;
export const Home = styled.div`
  width: 100%;
  /* height: 100vh; */
  position: relative;
  background: white;
  z-index: 1;
  background: ${(props) => (props.color ? props.color : "#27213c")};

  ${HomeContent} {
    padding-top: 200px;

    /* padding: 175px 42px 0; */

    ::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: -99;
      ${({ iconColor, color }) => {
        return css`
          background: ${iconColor == "yellow"
              ? `url(${backroundPathYellow})`
              : `url(${backroundPath})`},
            ${color ? color : "#27213c"};
        `;
      }};
      /* background: url(${backroundPath}),
        ${(props) =>
        props.color ? props.color : "#27213c"}; */
      background-size: 70%;
      background-repeat: no-repeat;
      background-position-x: right;
      background-position-y: top;
      opacity: 0.2;
    }
  }
`;

export const InfoPart = styled.div`
  transition: 0.9s ease;

  /* position: relative; */
  /* bottom: 170px; */
  height: 0px;
  display: none;
  /* flex-direction: column; */
  font-size: 0.5rem;
  width: 100%;
  span {
    display: flex;
    width: 100%;
    justify-content: center;
    color: white;
    font-family: "Nexa Bold";
    /* font-weight: bold; */
  }
`;
export const Overlay = styled.div`
  /* position: absolute; */
  height: 100%;
  width: 100%;
  transition: 0.5s ease;
`;
export const ProjectsSlider = styled.div`
  height: 400px;
  width: 100%;
  background: #9a1d2385;
  display: flex;
  place-items: center;
  width: 100%;
  height: 400px;
  a {
    text-decoration: none;
    color: white;
    font-family: "Nexa Bold";
  }
  svg {
    width: 100%;
    height: 100%;
  }

  &.gatsby-image-carousel-wrapper {
    height: 400px;
    width: 100%;
    padding: 2%;
  }
  .BrainhubCarousel {
    height: 310px;
  }
  .BrainhubCarousel__arrows span {
    width: 15px;
  }
  .BrainhubCarousel__arrows {
    background: transparent;
  }
  .BrainhubCarousel__arrows:hover {
    background: transparent;
    span {
      border-color: #27213c;
    }
  }

  .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
    background: #27213c;
  }
  .BrainhubCarousel__dots .BrainhubCarousel__dot {
    background: transparent;
  }
`;
export const SlideWrap = styled.div`
  :hover {
    transform: scale(1.4);
    /* margin: 0 1rem; */

    ${InfoPart} {
      display: flex;
      flex-direction: column;
      opacity: 1;
      height: 100%;
      position: absolute;
      top: 20%;
      padding: 2rem;
      color: white !important;
    }
    ${Overlay} {
      opacity: 0.3;
    }
  }
`;
export const IndexPageTemplate = ({ title, projects }) => (
  <ProjectsSlider>
    <Carousel
      arrows
      dots
      infinite
      slidesPerPage={3}
      breakpoints={{
        640: {
          slidesPerPage: 1,
        },
        900: {
          slidesPerPage: 3,
        },
      }}
    >
      {projects.project.map((project, index) => (
        <SlideWrap key={index} className="gatsby-image-carousel-wrapper">
          <Overlay>
            <a
              href={project.link}
              target="_blank"
              rel="noreferrer"
              aria-label="project-link"
              key={index}
            >
              <SvgBackground
                link={project.image.childImageSharp?.fluid.src}
                id={index}
              />
            </a>
          </Overlay>
          <InfoPart>
            <span>{project.title}</span>
            <HTMLContent content={project.description} />
          </InfoPart>
        </SlideWrap>
      ))}
    </Carousel>
  </ProjectsSlider>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { projects, title } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      {/* <SEO title="Home" /> */}
      <Home>
        <HomeContent>
          <IndexPageTemplate title={title} projects={projects} />
        </HomeContent>
      </Home>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        projects {
          project {
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
            alt
            link
          }
        }
      }
    }
  }
`;
