import React from "react";
const SvgBackground = ({ link, id, style }) => {
  return (
    <svg viewBox="0 0 392 432">
      <defs>
        <pattern
          id={id}
          patternUnits="userSpaceOnUse"
          width="100%"
          height="100%"
        >
          <rect height="100%" width="100%" fill="#9a1c1fe8" />

          <image
            xlinkHref={link}
            style={style}
            x="0"
            y="-10"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
          />
        </pattern>
      </defs>

      <path
        fill={`url(#${id})`}
        filter="url(#sparklin)"
        d="M378.5,202.1c0.1-4.1-1.5-5.5-5.3-5.7c-5.7-0.3-11.3-0.8-17-1.4c-15.7-1.5-15-1.7-19.6-17c-1.9-6.3,0.2-8.6,4.7-11.3
  c7.1-4.5,13.9-9.5,21.1-13.8c2.8-1.6,3.2-2.9,1.8-5.3c-6.7-11.4-13.5-22.8-20-34.3c-2.1-3.8-3.7-3.4-6.3-0.7
  c-8.5,8.6-17,17.1-25.5,25.6c-9.2-12.9-20.5-24.3-33.4-33.5c8.2-8.1,16.5-16,24.5-24.2c1.4-1.4,7.4-3.7,1.3-7.2
  c-11.3-6.4-22.8-12.6-33.8-19.6c-4.2-2.6-6-1.1-8,2c-4.9,7.5-9.6,15-14.5,22.5c-1.1,1.7-2.3,4.5-4.3,3.7c-6-2.4-12.3-3.7-18.5-5.1
  c-2.7-0.6-2.8-3.3-3-5.7c-0.6-8.8-1.5-17.5-1.7-26.3c-0.1-4.6-1.1-6.4-6.1-6.3c-12.1,0.3-24.3,0.3-36.5,0c-5.2-0.1-6.7,1.9-6.8,6.8
  c-0.3,8.8-1.2,17.5-1.7,26.3c-0.2,2.8-0.5,5.8-3.8,5.6c-6.9-0.4-12.6,5.5-19.6,3.8c-0.7-0.2-1-1.7-1.6-2.5
  c-4.9-7.5-9.9-14.9-14.9-22.3c-2.1-3.1-3.6-5.3-8.2-2.2c-9.1,6.1-18.8,11.4-28.6,16.2c-7.4,3.6-9.3,7.3-3.9,14.3
  c2.2,2.9,3.5,6.8,5.3,10.2c6.6,13.2,6.5,13.2-3.9,24.2c-0.9,0.9-2,1.7-2.7,2.7c-2.4,3.5-5,3.3-8.5,1.3c-7.3-4.2-14.9-7.9-22.4-11.6
  c-3.6-1.8-6.5-2.5-8.9,2.9c-3.1,6.7-7.8,12.6-10.7,19.5h0c-0.9,0.6-1.8,1.2-2.6,1.8c0,5-6.1,7.7-5,13.9l7.4,8.2
  c3.1,1.8,6,3.9,9.1,5.8c11.6,7.1,11.6,7.1,8,19.7c-0.4,1.5-1,3-0.9,4.5c0.1,3.2-0.8,4.9-2.5,5.8c-3.1,0.4-6.3,0.7-9.3,1.2
  c-7.1,0.3-14.2,0.9-21.3,1.3c-2.7,0.2-5.8,0-4.9,4c-0.5,0.3-0.9,0.6-1.4,1c-0.3,12.2,0,24.4-0.2,36.6c0,2.9,0.2,5.6,2.2,7.8
  c8,0.2,16,0.3,24,0.6c7.4,0.3,14.6,0.8,14.1,11.3c-0.1,2.4,1.2,5.1,2.5,7.2c2.7,4.4,0.4,6.5-3,8.7c-7.3,4.6-14.4,9.7-21.9,14
  c-4.3,2.5-4.2,4.7-1.9,8.5c6.1,10,12.5,19.9,17.6,30.5c3.6,7.4,6.1,4.6,9.9,0.8c7.7-7.7,15.4-15.4,23.1-23.1
  c9.3,12.9,20.7,24.2,33.6,33.3c-7.9,8-16,16-24.2,23.7c-5.5,5.2-2.8,6.5,1.2,8.9c10.8,6.3,21.6,12.5,32.3,18.9
  c2.7,1.6,3.6,0.7,5.1-1.9c4.7-7.9,10-15.4,15.3-23c1.5-2.1,2.8-5.9,6.3-4.1c4.5,2.4,9.5,2.4,14.2,3.6c4.2,1.1,5.9,2.6,6,7.1
  c0.2,8.3,1.6,16.5,1.7,24.7c0.1,5.6,1.7,7.8,7.6,7.5c10.1-0.5,20.2-0.1,30.2-0.1c10.8,0,10.8,0,11.1-11.1c0.1-3.1,0.2-6.2,0.4-9.3
  c0.9-16.4,0.9-16.3,17.1-20.6c1-0.3,1.9-0.7,2.8-1.2c3.6-2.1,5.7-0.2,7.5,2.6c4.3,6.6,9.5,12.8,12.9,19.8c3.4,7.2,6.8,7.3,12,2.4
  c7.9-3.1,15.1-7.4,22-12.2c0,0,0,0,0,0c1.4-0.6,2.8-1.3,4.2-1.9c5.9-2.7,9.5-6.1,4.8-12.9c-2.6-3.8-4.4-8-6.6-12
  c-6.7-12.5-6.7-12.5,2.9-22.4c1.4-1.5,3-2.9,4.2-4.6c2.2-3.3,4.4-3.1,7.7-1.4c8,4.3,16.5,7.7,24.4,12.3c3.9,2.3,5.5,1,7.2-2
  c3.9-6.9,7.8-13.8,11.7-20.6c3.1-5.5,9.6-10.9,8.3-16.5c-1.3-5.6-9.7-7.3-14.9-11.1c-14.4-10.5-14.3-10.3-10.1-27.3
  c1.2-4.6,3.3-6.4,7.9-6.4c8.3,0,16.5-0.6,24.8-0.6c4.4,0,6.6-1.2,6.5-6.2C378.2,227,378.2,214.6,378.5,202.1z"
      />
    </svg>
  );
};

export default SvgBackground;
